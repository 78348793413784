import * as React from 'react'

import { useTranslation } from 'react-i18next'
import { useLanguage } from '@hooks/useLanguage'
import { DealerContacts } from '@layout/DealerContacts'
import { Page, PageContainerWithHead, PageContent } from '@layout/Page'
import { ContactForm } from '@forms/ContactForm'
import { DeliveryMap } from './DeliveryMap'

export const DeliveryPage: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()
  const pageId = React.useId()

  const breadCrumbs = React.useMemo(
    () => [{ to: routes.Index, title: t('pages.main.title') }, { title: t('pages.delivery.title') }],
    [t, routes]
  )

  return (
    <Page id={pageId}>
      <PageContainerWithHead showCar title={t('pages.delivery.title')} breadCrumbs={breadCrumbs}>
        <PageContent subtitle={t('pages.delivery.text')}>
          <DeliveryMap />
          <ContactForm topic="other" />
          <DealerContacts />
        </PageContent>
      </PageContainerWithHead>
    </Page>
  )
}
