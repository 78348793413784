import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { formatEuroInEstonian } from '@model/language'
import europeImg from '@assets/images/europe.png'
import { Button } from '@mui/material'
import { FlexContainer } from '@layout/FlexContainer'
import { DeliveryForm } from '../DeliveryForm'
import styles from './DeliveryMap.module.css'

export type TypeDefinition = Readonly<{
  label: string
  value: string
  price: number
}>

export const DeliveryMap: React.FC = () => {
  const { t } = useTranslation()

  const countryOptions: ReadonlyArray<TypeDefinition> = React.useMemo(
    () => [
      {
        label: t('pages.delivery.map.countries.latvia'),
        value: 'Latvia',
        price: 200
      },
      {
        label: t('pages.delivery.map.countries.lithuania'),
        value: 'Lithuania',
        price: 300
      },
      {
        label: t('pages.delivery.map.countries.finland'),
        value: 'Finland',
        price: 350
      },
      {
        label: t('pages.delivery.map.countries.germany'),
        value: 'Germany',
        price: 850
      }
    ],
    [t]
  )

  return (
    <FlexContainer noGap className={styles.deliveryMapContainer}>
      <DeliveryForm />
      <div className={styles.mapContainer}>
        <img src={europeImg} alt="Europe map" />
        <div className={styles.countryList}>
          <ul>
            {countryOptions.map((option) => {
              return (
                <li key={option.value} value={option.value}>
                  {option.label} {t('pages.delivery.map.from')}{' '}
                  <Button variant="text">{formatEuroInEstonian(option.price)}</Button>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </FlexContainer>
  )
}
